<template>
  <v-row class="justify-space-between mb-4">
    <v-col>
      <p style="color: #4477ad; font-size: 26px">
        {{ data.namePage }}
        <span style="margin-left: 5px">({{ getListFilter.length }})</span>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinAlert from '@/mixins/mixinAlert'
import axios from 'axios'
import utils from '@/utils'
import config from '@/config'

export default {
  mixins: [mixinAlert],
  data() {
    return {
      file: null,
      loading: false,
    }
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      getListFilter(_, getters) {
        return getters[this.getPathStore + '/getListFilter']
      },
    }),
    getPathStore() {
      return this.data.path + 'Store'
    },
  },
  methods: {
    openSelectWindow() {
      this.$refs.file.click()
    },
    async onFilePicked(e) {
      const confirmation = await this.mixinAlertConfirm(this.$t('confirmOccurrencesUpload'))
      if (!confirmation) {
        this.$refs.file.value = ''
        return
      }

      const { files } = e.target
      this.loading = true
      const bodyFormData = new FormData()
      bodyFormData.append('arquivo', files[0])

      axios
        .post(`${config.API_GATEWAY_URL}/order-delivery/status/file`, bodyFormData, {
          ...utils.makeDefaultMutipartPostData(),
        })
        .then(() => {
          this.mixinAlertSuccess(this.$t('fileUploadConcluded'))
        })
        .catch((error) => {
          this.mixinAlertError(error)
        })
        .then(() => {
          this.$refs.file.value = ''
          this.loading = false
        })
    },
    ...mapActions({
      uploadFile(dispatch, payload) {
        return dispatch('occurrencesStore/uploadFile', payload)
      },
    }),
  },
}
</script>
